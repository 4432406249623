
export const openProject = $el => {

    const projectJSON = JSON.parse( $el.getAttribute('data-project-json') );
    const $projectModal = document.querySelector('.project-modal');
    const $images = $projectModal.querySelector('[data-project-images]');
    let sliderHTML = '';

    Object.keys(projectJSON).forEach(name => {
        const $elem = $projectModal.querySelector(`[data-project-${name}]`);
        if( $elem ){
            $elem.innerHTML = projectJSON[name] || '';
        }
    });

    projectJSON.images.forEach(obj => {
        sliderHTML += `<div><img src="${obj.url}" title="${obj.name}" /></div>`;
    });
    $images.innerHTML = sliderHTML;

    $projectModal.querySelector('[data-project-link-url]').href = projectJSON.link || '';

    document.body.classList.add('modal-open');
    document.body.insertAdjacentHTML('beforeend', '<div class="project-backdrop modal-backdrop modal-backdrop-nav"></div>');

    setTimeout(() => {
        $projectModal.classList.add('show');
        $projectModal.classList.add('d-block');
        document.querySelector('.project-backdrop').classList.add('in');
    }, 300);

    setTimeout(() => {
        $projectModal.classList.add('in');
    }, 900);
    
}
