
import jsActions from './jsActions/';

export const jsActionsInit = (() => {

    const elements = document.querySelectorAll('[data-js-action]');
    Array.from(elements).forEach($el => {

        $el.addEventListener('click', event => {
            event.preventDefault();
            const actionName = $el.getAttribute('data-js-action');
            jsActions[actionName] && jsActions[actionName]($el);
        }, false);
        
    });

})()
