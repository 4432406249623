
export const closeProject = () => {

    const $modal = document.querySelector('.project-modal');
    const $backdrop = document.querySelector('.project-backdrop');

    $modal.classList.remove('in');
    $backdrop.classList.remove('in');

    setTimeout(() => {
        document.body.classList.remove('modal-open');
        $modal.classList.remove('show');
        $modal.classList.remove('d-block');
        $backdrop.remove();
    }, 450);
    
}
