
import { loadingButton } from './helpers/loadingButton';
import { resetButton } from './helpers/resetButton';
import Form from 'formjs-plugin';

const initFormLogin = () => {
    const $formLogin = document.querySelector('form[name="login"]');
    if( !$formLogin ){ return }
    const $loginMsg = $formLogin.querySelector('.login-message');
    const $loginMsgText = $loginMsg.querySelector('p');

    new Form( $formLogin, { fieldOptions: { preventPasteFields: '', strictHtmlValidation: false } } );

    $formLogin.addEventListener('fjs.form:submit', event => {
        const $btn = $formLogin.querySelector('[type="submit"]');

        loadingButton($btn);
        $loginMsg.classList.remove('d-block');
        $loginMsgText.innerHTML = '';

        event.data
        .then(response => {
            if( response.result ){
                window.location.reload();
            } else {
                $loginMsgText.innerHTML = response.message;
            }
        })
        .catch(() => {
            $loginMsgText.innerHTML = 'An error occurred. Please retry.';
        })
        .finally(() => {
            resetButton($btn);
            if( $loginMsgText.innerHTML.trim() !== '' ){
                $loginMsg.classList.add('d-block');
            }
        });
    });
}

export const pageInit = (() => {

    window.addEventListener('load', () => {
        document.querySelector('#pageLoadingScreen').remove();
    });

    initFormLogin();

})()
