
export const loadingButton = ( $button, replace = false ) => {

    $button.setAttribute('data-html', $button.innerHTML);
    $button.innerHTML = '<span class="icons icon-settings icon-spin"></span> ' + (replace ? '' : '&nbsp;&nbsp;' + $button.innerHTML);
    $button.classList.add('disabled');
    $button.disabled = true;
    $button.blur();

}
