
import { closeModal }       from './closeModal';
import { closeProject }     from './closeProject';
import { logout }           from './logout';
import { openModal }        from './openModal';
import { openProject }      from './openProject';
import { scrollToElement }  from './scrollToElement';

export default {

    closeModal,
    closeProject,
    logout,
    openModal,
    openProject,
    scrollToElement
    
}