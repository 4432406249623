
export const openModal = $el => {

    const modalSelector = $el.getAttribute('data-reference');

    document.body.classList.add('modal-open');
    document.body.insertAdjacentHTML('beforeend', '<div class="modal-backdrop modal-backdrop-nav fade bg-transparent"></div>');
    document.querySelector(modalSelector).classList.add('show');
    document.querySelector(modalSelector).classList.add('d-block');

    setTimeout(() => {
        const $elems = document.querySelectorAll(modalSelector+', .modal-backdrop');
        Array.from($elems).forEach($elem => {
            $elem.classList.add('in');
        });
    }, 200);
    
}
