
import { ajaxOptions } from '../helpers/ajaxOptions';

export const logout = () => {

    const $btnLogout = document.querySelector('.btn-logout');
    const $btnsHeader = $btnLogout.closest('.btns-header');
    const logoutUrl = $btnLogout.getAttribute('data-ajax-url');

    $btnLogout.classList.add('d-none');
    $btnsHeader.insertAdjacentHTML('afterbegin', '<div class="btn btn-icon btn-loading"><i class="icons icon-settings icon-spin"></i></div>');

    fetch(logoutUrl, ajaxOptions)
    .then(response => {
        if( !response.ok ){
            return Promise.reject(response);
        }
        return response.json();
    })
    .then(() => {
        window.location.reload();
    })
    .catch(() => {
        alert('An error occurred. Retry.');
    })
    .finally(() => {
        $btnsHeader.querySelector('.btn-loading').remove();
        $btnLogout.classList.remove('d-none');
    });
    
}
