
export const closeModal = () => {

    const $modal = document.querySelector('.modal.in');

    $modal.classList.remove('in');
    document.querySelector('.modal-backdrop').classList.remove('in');
    
    setTimeout(() => {
        document.body.classList.remove('modal-open');
        document.querySelector('.modal-backdrop').remove();
        $modal.classList.remove('show');
        $modal.classList.remove('d-block');
    }, 200);
    
}
