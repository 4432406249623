
export const ajaxOptions = {
    cache:          'no-store',
    credentials:    'same-origin',
    headers: {
                    'Content-Type': 'application/json'
    },
    method:         'GET',
    mode:           'same-origin',
    redirect:       'follow'
}
